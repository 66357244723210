<template>
  <el-dialog
    v-model="visibleDialog"
    :title="title"
    width="1300px"
    top="10vh"
    draggable
    :append-to-body="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="section" style="height:65vh;" v-loading="loading">
      <div class="header">
        <el-steps :active="stepActive" simple style="margin-bottom:20px;">
          <el-step title="基础信息设置" />
          <el-step title="流程字段设置" />
          <el-step title="审批流程设置" />
        </el-steps>
      </div>

      <div class="body">
        <el-form :model="form" ref="form" :rules="rules" label-width="140px" v-if="stepActive==0">
          <el-form-item label="类型" prop="type_id" >
            <el-select v-model="form.type_id" placeholder>
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="item in type_list"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-col :span="14">
              <el-input v-model="form.name" placeholder="请输入名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="适用范围">
            <el-radio-group v-model="form.is_everyone">
              <el-radio :label="true">全员</el-radio>
              <el-radio :label="false">指定范围</el-radio>
            </el-radio-group>
            <div style="width:100%;" v-if="!form.is_everyone">
              <applicant-choose @success="applicantSuccess"></applicant-choose>
              <div>
                <el-tag
                  style="margin:0 10px 10px 0;"
                  v-for="(item,i) in form.applicant_list"
                  :key="item.id"
                  closable
                  :type="`${item.type==0?'success':item.type==1?'danger':''}`"
                  @close="onApplicantRemove(i)"
                >
                  <div style="display:flex;align-items: center;">
                    <el-icon v-if="item.type==1">
                      <FolderOpened />
                    </el-icon>
                    <el-icon v-if="item.type==0">
                      <UserFilled />
                    </el-icon>
                    <el-icon v-if="item.type==2">
                      <User />
                    </el-icon>
                    {{item.type==0?'人员':item.type==1?'部门':'角色'}}:{{item.name}}
                  </div>
                </el-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="自动审批">
            <el-radio-group v-model="form.auto_approve">
              <el-radio :label="0">仅首个节点需审批，其余自动同意</el-radio>
              <el-radio :label="1">每个节点都需要审批</el-radio>
            </el-radio-group>
            <div class="help-block">当同一个审批人重复审批同一单据时</div>
          </el-form-item>

          <el-form-item label="异常处理">
            <el-radio-group v-model="form.deal_exception">
              <el-radio :label="0">自动同意</el-radio>
              <el-radio :label="1">转交给指定人员处理</el-radio>
            </el-radio-group>
            <div style="width:100%" v-if="form.deal_exception==1">
              <el-tag
                type="info"
                v-if="form.deal_exception_emp"
              >{{form.deal_exception_emp.chs_name}}</el-tag>
              <emp-choose :single="true" icon="Check" text="选择人员" @success="expEmpChange"></emp-choose>
            </div>
            <div class="help-block">审批节点内成员离职、为空等情况的处理方式</div>
          </el-form-item>

          <el-form-item label="停用">
            <el-switch v-model="form.is_stop"></el-switch>
          </el-form-item>
        </el-form>
        <edit-form ref="editForm" v-if="stepActive==1" :model="form"></edit-form>
        <edit-approve ref="editApprove" v-if="stepActive==2" :model="form"></edit-approve>
      </div>
    </div>

    <template #footer>
      <el-button @click="onPrev" v-if="stepActive!=0" :loading="saving">上一步</el-button>
      <el-button @click="onNext" v-if="stepActive!=2 " :loading="saving">下一步</el-button>
      <el-button type="primary" @click="onSubmit" v-if="stepActive==2 " :loading="saving">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import EditForm from "./edit_form.vue";
import EditApprove from "./edit_approve.vue";
import ApplicantChoose from "../applicant_choose.vue";
import EmpChoose from "@/views/components/emp_choose.vue";

export default {
  components: {
    EditForm,
    EditApprove,
    ApplicantChoose,
    EmpChoose,
  },
  data() {
    return {
      loading: false,
      visibleDialog: false,
      saving: false,
      stepActive: 0,
      title: "",
      form: {},
      rules: {
        type_id: [{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //类型列表
      type_list: [],
    };
  },
  props: ["model"],
  emits: ["success"],
  watch: {
    model: {
      handler() {
        this.form = {};
        if (this.model) {
          this.visibleDialog = true;
          this.form = Object.assign(
            {
              is_everyone: true,
              auto_approve: 0,
              deal_exception: 0,
            },
            this.model
          );
          this.stepActive = 0;
          if (this.model.id) {
            this.loadDtl();
            this.title = "修改流程";
          } else {
            this.title = "新增流程";
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.loadType();
  },
  methods: {
    loadType() {
      this.$http.get("seller/v1/flowTemplate/types").then((res) => {
        if (res.code == 0) {
          this.type_list = res.data;
        }
      });
    },

    /**
     * 上一步
     */
    onPrev() {
      this.stepActive--;
    },
    /**
     * 下一步
     */
    onNext() {
      if (this.stepActive == 0) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.stepActive++;
          }
        });
      }
      if (this.stepActive == 1) {
        this.$refs.editForm.validate((o) => {
          if (o.valid) {
            this.stepActive++;
            this.form.form_list = o.form;
          }
        });
      }
    },

    /**
     * 获取详情
     */
    loadDtl() {
      this.$http
        .get("seller/v1/flowTemplate/dtl?id=" + this.model.id)
        .then((res) => {
          if (res.code == 0) {
            this.form = res.data;
          }
        });
    },

    /**
     * 适用范围选择更改回调
     */
    applicantSuccess(list) {
      this.form.applicant_list = list;
    },

    /**
     * 移除申请人
     */
    onApplicantRemove(i) {
      this.form.applicant_list.splice(i, 1);
    },

    /**
     * 异常指定处理人
     */
    expEmpChange(list) {
      this.form.deal_exception_emp = {
        id: list[0].id,
        chs_name: list[0].chs_name,
        mobile_phone: list[0].mobile_phone,
      };
      this.form.deal_exception_emp_id = list[0].id;
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.editApprove.validate((o) => {
        if (o.valid) {
          this.form.node_list = o.node_list;
        }
      });

      if (this.form.node_list.length <= 0) {
        this.$message.error("请设置流程节点");
        return;
      }

      this.saving = true;

      this.$http
        .post("/seller/v1/flowTemplate/edit", this.form)
        .then((res) => {
          if (res.code == 0) {
            this.visibleDialog = false;
            this.$message.success(res.msg);
            this.$emit("success", true);
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style scoped>
.section {
  display: flex;
  flex-direction: column;
}
.section .body {
  flex-grow: 1;
  height: 1px;
}
</style>